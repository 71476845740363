// eslint-disable-next-line import/order
import * as pdfjs from 'pdfjs-dist/es5/build/pdf';
import { getThumbnail, storeThumbnail } from './local-thumbnail-db';

async function canvasToObjectUrl(
  canvas: HTMLCanvasElement,
  signal?: AbortSignal,
): Promise<{ url: string; blob: Blob | undefined }> {
  return new Promise((resolve, reject) => {
    if (signal?.aborted) {
      return resolve({ url: '', blob: undefined });
    }

    return canvas.toBlob(
      (blob) => {
        console.log(
          'TCL ~ file: generate-local-thumbnail.ts ~ line 16 ~ returnnewPromise ~ blob',
          blob,
        );
        if (!blob) {
          return reject(new Error('No thumbnail blob was returned.'));
        }

        const url = URL.createObjectURL(blob);
        return resolve({ url, blob });
      },
      'image/jpeg',
      0.75,
    );
  });
}

function cancelIfTaskAborted(signal?: AbortSignal, localUrl?: string | undefined): void {
  if (!signal?.aborted) {
    return;
  }

  if (localUrl) {
    URL.revokeObjectURL(localUrl);
  }

  throw new DOMException('Task was canceled', 'AbortError');
}

function generateVideoThumbnail(
  file: File,
  signal?: AbortSignal,
  seekTo = 2.0,
): Promise<{ url: string; blob: Blob | undefined }> {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    cancelIfTaskAborted(signal);

    const videoPlayer = document.createElement('video');
    const localUrl = URL.createObjectURL(file);
    videoPlayer.setAttribute('src', localUrl);

    cancelIfTaskAborted(signal, localUrl);

    videoPlayer.load();

    cancelIfTaskAborted(signal, localUrl);

    videoPlayer.addEventListener('error', (err) => {
      URL.revokeObjectURL(localUrl);
      reject(new Error(`error when loading video file: ${err}`));
    });

    // eslint-disable-next-line consistent-return
    videoPlayer.addEventListener('loadedmetadata', () => {
      cancelIfTaskAborted(signal, localUrl);

      if (videoPlayer.duration < seekTo) {
        URL.revokeObjectURL(localUrl);
        reject(new Error('Video is too short.'));
      }

      // eslint-disable-next-line consistent-return
      videoPlayer.addEventListener('seeked', async () => {
        cancelIfTaskAborted(signal, localUrl);

        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        try {
          if (ctx?.canvas) {
            const res = await canvasToObjectUrl(ctx.canvas);
            return resolve(res);
          }
          return reject(new Error('No video thumbnail context was returned.'));
        } catch (err) {
          return reject(new Error(`No video thumbnail generated. Error: ${err}`));
        } finally {
          URL.revokeObjectURL(localUrl);
        }
      });

      videoPlayer.currentTime = seekTo;
    });
  });
}

async function generatePdfThumbnail(
  file: File,
  signal?: AbortSignal,
): Promise<{ url: string; blob: Blob | undefined }> {
  cancelIfTaskAborted(signal);

  const localUrl = URL.createObjectURL(file);
  const loadingTask = pdfjs.getDocument(localUrl);
  const pdf = await loadingTask.promise;

  cancelIfTaskAborted(signal, localUrl);

  const page = await pdf.getPage(1);

  cancelIfTaskAborted(signal, localUrl);

  const viewport = page.getViewport({ scale: 1 });

  const canvas = document.createElement('canvas');
  // two times for retina displays
  canvas.width = 250 * 2;
  canvas.height = 128 * 2;

  const scale = Math.max(canvas.width / viewport.width, canvas.height / viewport.height);

  URL.revokeObjectURL(localUrl);

  cancelIfTaskAborted(signal);

  await page.render({
    canvasContext: canvas.getContext('2d'),
    viewport: page.getViewport({ scale }),
  }).promise;

  cancelIfTaskAborted(signal);

  return canvasToObjectUrl(canvas);
}

const cache = new Map<string, string>();

export default async (
  localFile: File,
  filename: string,
  signal?: AbortSignal,
): Promise<string | undefined> => {
  if (cache.has(filename)) {
    return cache.get(filename);
  }

  const dbBlob = await getThumbnail(filename);

  if (dbBlob) {
    const localUrl = URL.createObjectURL(dbBlob);
    cache.set(filename, localUrl);

    return localUrl;
  }

  if (signal?.aborted) {
    return undefined;
  }

  if (!filename) {
    console.error('No filename was found for local fetch');
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fallbackExt = filename.split('.').pop()!;

  const { type } = localFile;

  let location: string | undefined;
  let blob: Blob | undefined;

  try {
    if (/^image\/.*$/i.test(type)) {
      location = URL.createObjectURL(localFile);
      blob = localFile;
    } else if (/^video\/.*$/i.test(type) || ['avi', 'mpg'].includes(fallbackExt)) {
      const res = await generateVideoThumbnail(localFile, signal);
      location = res.url;
      blob = res.blob;
    } else if (type === 'application/pdf') {
      const res = await generatePdfThumbnail(localFile, signal);
      location = res.url;
      blob = res.blob;
    }

    if (location && blob) {
      cache.set(filename, location);
      storeThumbnail(filename, blob);
    }
  } catch (error) {
    if (error.code !== DOMException.ABORT_ERR) {
      console.error(error);
    }
  }

  return location;
};

export const clearLocaleThumbnailCache = (): void => cache.clear();
