
import Vue, { PropType } from 'vue';
import PdfDocument from '@/components/PdfDocument.vue';
import PdfPage from '@/components/PdfPage.vue';

const defaultData = {
  scale: 1.5,
  currentPage: 1,
};

export default Vue.extend({
  name: 'FileViewerPdf',

  components: {
    PdfDocument,
    PdfPage,
  },

  data() {
    return { ...defaultData };
  },

  props: {
    fileUrl: String,
    visible: Boolean as PropType<boolean>,
  },

  watch: {
    visible() {
      this.setDefaults();
    },
  },

  methods: {
    setDefaults() {
      const { scale, currentPage } = defaultData;

      this.scale = scale;
      this.currentPage = currentPage;
    },

    zoomReset() {
      this.scale = defaultData.scale;
    },

    zoomIn() {
      this.scale += 0.5;
    },

    zoomOut() {
      if (this.scale > 1.0) {
        this.scale -= 0.5;
      }
    },

    currentPageChanged(pageNum: number) {
      const container = document.querySelector('#viewer') as HTMLElement;

      if (container.scrollTop > 0) {
        this.currentPage = pageNum;
      }
    },

    scrollToPage(pageNumber: number) {
      const element = document.querySelector(
        `#page-${pageNumber}`,
      ) as HTMLElement;

      element?.scrollIntoView();
    },
  },
});
