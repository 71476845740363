async function read(
  controller: ReadableStreamDefaultController<Uint8Array>,
  reader: ReadableStreamDefaultReader<Uint8Array>,
  total: number,
  loaded: number,
  progressCb: (progress: number) => void,
): Promise<void> {
  const { done, value } = await reader.read();

  if (done) {
    controller.close();
    return;
  }

  if (!value?.byteLength) {
    throw new Error('Reader Value has no byte length');
  }

  const loadedBytes = loaded + value.byteLength;
  const progress = (loaded / total) * 100;

  progressCb(progress);
  controller.enqueue(value);

  read(controller, reader, total, loadedBytes, progressCb);
}

export default async (
  response: Response,
  progressCb: (progress: number) => void,
): Promise<Response> => {
  if (!response.ok) {
    throw new Error(`[${response.status}] ${response.statusText} <${response.url}>`);
  }

  if (!response.body) {
    throw new Error('ReadableStream not supported in this browser');
  }

  const contentEncoding = response.headers.get('content-encoding');
  const contentLength = response.headers.get(contentEncoding ? 'x-file-size' : 'content-length');

  if (contentLength === null) {
    progressCb(-1);
    return response;
  }

  const total = parseInt(contentLength, 10);

  return new Response(
    new ReadableStream({
      async start(controller) {
        const reader = response.body?.getReader();

        if (!reader) {
          throw new Error('Can not read response body');
        }

        await read(controller, reader, total, 0, progressCb);
      },
    }),
  );
};
