var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.handleIntersect),expression:"handleIntersect"}],staticClass:"item dark:bg-gray-800 hover:border-gray-500 hover:shadow-xl hover:scale-105"},[_c('div',{staticClass:"flex flex-grow flex-column",on:{"click":_vm.openFile}},[_c('div',{staticClass:"flex w-full h-32 bg-gray-100 bg-no-repeat bg-cover rounded-t-lg dark:bg-gray-600",attrs:{"alt":"image"}},[(_vm.showImage)?_c('VImg',{attrs:{"lazy-src":_vm.preloadDataUrl,"src":_vm.thumbnailDataUrl,"max-height":"229","max-width":"250","position":"top center"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('VRow',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,1480835182)}):_vm._e()],1),_c('div',{staticClass:"container text-md dark:bg-gray-800 md:p-4 lg:text-base"},[_c('div',{staticClass:"mb-2 text-base font-medium break-words truncate-overflow hyphens"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('div',{staticClass:"flex items-center justify-between text-gray-700 dark:text-white"},[_c('div',{staticClass:"flex flex-wrap justify-between w-full"},[_c('span',[_vm._v(_vm._s(_vm.modifiedDate))]),_c('span',[_vm._v(_vm._s(_vm.fileSize))])])])])]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"leading-6 appearance-none focus:outline-none",attrs:{"disabled":!_vm.isOnline || _vm.deactivateFavorite},on:{"click":_vm.toggleFavorite}},[_c('VIcon',{class:{
          'text-black hover:text-highlight dark:text-white': _vm.isOnline && !_vm.deactivateFavorite,
          'text-gray-400': !_vm.isOnline || _vm.deactivateFavorite,
        },attrs:{"hover:text-highlight":""}},[_vm._v(_vm._s(_vm.favorite ? 'mdi-heart' : 'mdi-heart-outline'))])],1),_c('VMenu',{attrs:{"bottom":"","origin":"center center","transition":"slide-y-transition","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({staticClass:"leading-6 appearance-none focus:outline-none",attrs:{"disabled":!_vm.shareable}},'button',attrs,false),on),[_c('VIcon',{class:{
          'text-black hover:text-highlight dark:text-white': _vm.shareable,
          'text-gray-400': !_vm.shareable,
        }},[_vm._v("mdi-share-variant-outline")])],1)]}}])},[_c('VList',{attrs:{"dense":""}},_vm._l((_vm.menuItems),function(item){return _c('VListItem',{key:item.key,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.menuClicked(item.key)}}},[_c('VListItemIcon',[_c('VIcon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('VListItemTitle',[_vm._v(_vm._s(_vm.$t(item.titleKey)))])],1)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }