import { File } from '@/interfaces/file.interface';
import isLocalFile from '@/utils/is-local-file';
import downloadProgress from './download-progress';

export default async (
  item: File,
  token: string,
  progressCb: (progress: number) => void,
): Promise<void> => {
  const ext = item?.extension ?? item?.location?.split('.')?.pop();
  const downloadName = `${item.title}.${ext}`;

  let downloadUrl: string;

  if (!isLocalFile(item.location)) {
    const url = `${process.env.VUE_APP_BASE_URL}/${item.location}?token=${token}`.replaceAll(
      '//files',
      '/files',
    );

    const request = await fetch(url);
    const response = await downloadProgress(request, (progress: number) => progressCb(progress));
    const data = await response.blob();

    downloadUrl = URL.createObjectURL(data);
  } else {
    downloadUrl = item.location;
  }

  const a = document.createElement('a');

  a.href = downloadUrl;
  a.download = downloadName;
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
};
