
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-extraneous-dependencies */

import downloadProgress from '@/utils/download-progress';
import * as pdfjs from 'pdfjs-dist/es5/build/pdf';
import Vue from 'vue';
import PdfjsWorker from 'worker-loader!pdfjs-dist/es5/build/pdf.worker';

pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();

export default Vue.extend({
  name: 'PdfDocument',

  data() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { pdf: null, loading: true, progress: 0 };
  },

  props: {
    fileUrl: String,
  },

  computed: {
    numPages(): number {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (this.pdf as any)?.numPages ?? 0;
    },
  },

  created() {
    this.loadPdf();
  },

  methods: {
    async loadPdf() {
      const request = await fetch(this.fileUrl);
      const response = await downloadProgress(request, (progress: number) => {
        this.$nextTick(() => {
          this.progress = Math.ceil(progress);
        });
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const loadingTask = pdfjs.getDocument(url);

      this.pdf = await loadingTask.promise;
      this.loading = false;
    },
  },
});
