
import { FileGridItemMenuKey } from '@/enums/file-grid-item-menu-key.enum';
import { FileGridMenuItem } from '@/interfaces/file-grid-menu-item.interface';
import { File } from '@/interfaces/file.interface';
import OnlineMixin from '@/mixins/online';
import { FavoriteActions } from '@/store/favorite/actions.enum';
import { LocalFileActions } from '@/store/local-file/actions.enum';
import generateLocalThumbnail from '@/utils/generate-local-thumbnail';
import isLocalFile from '@/utils/is-local-file';
import createShareLink from '@/utils/share-link';
import thumbnailUri from '@/utils/thumbnail-uri';
import formatDate from '@/utils/transform/date';
import fileSize from '@/utils/transform/file-size';

// eslint-disable-next-line import/order
import Vue, { PropType } from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'GridFileItem',

  mixins: [OnlineMixin],

  data() {
    let menuItems = [{
      titleKey: 'fileGridItemMenu.txtGenerateLink',
      icon: 'mdi-link',
      key: FileGridItemMenuKey.GENERATE_LINK,
    },
    {
      titleKey: 'fileGridItemMenu.txtOpenEmail',
      icon: 'mdi-email-outline',
      key: FileGridItemMenuKey.OPEN_EMAIL,
    }] as FileGridMenuItem[];

    if (process.env.VUE_APP_SHOW_LOCAL_FILES === 'true') {
      menuItems = [...menuItems, {
        titleKey: 'fileGridItemMenu.txtSaveLocal',
        icon: 'mdi-folder-download-outline',
        key: FileGridItemMenuKey.SAVE_LOCAL,
      }];
    }

    return {
      controller: null as null | AbortController,
      showImage: false,
      preloadDataUrl: null as null | string,
      thumbnailDataUrl: null as null | string,
      localThumbnailGenerated: false,
      menuItems,
    };
  },

  props: {
    item: Object as PropType<File>,
    deactivateFavorite: Boolean,
  },

  computed: {
    ...mapGetters('auth', ['token', 'userId']),
    ...mapGetters('locale', { localeId: 'currentId' }),
    ...mapGetters('favorite', ['isFavorite']),

    preloadThumbnailUrl(): string {
      if (!this.item?.thumbnail) {
        return '';
      }

      if (isLocalFile(this.item.thumbnail.location)) {
        return this.item.thumbnail.location;
      }

      return this.thumbnailUrl.replace('/250/', '/50/');
    },

    thumbnailUrl(): string {
      if (!this.item?.thumbnail) {
        return '';
      }

      if (isLocalFile(this.item.thumbnail.location)) {
        return this.item.thumbnail.location;
      }

      return thumbnailUri(this.item.thumbnail, 250);
    },

    fileSize(): string {
      return fileSize(+this.item.size);
    },

    modifiedDate(): string {
      return formatDate(this.item.modified);
    },

    favorite(): boolean {
      return this.isFavorite(this.item.id);
    },

    shareable(): boolean {
      return Boolean(+this.item.shareable);
    },
  },

  methods: {
    ...mapActions('favorite', {
      addFavorite: FavoriteActions.CREATE,
      removeFavorite: FavoriteActions.DELETE,
    }),

    ...mapActions('localFile', {
      saveToLocal: LocalFileActions.SAVE_FILE,
      openLocalFile: LocalFileActions.OPEN_FILE,
    }),

    async cacheImage() {
      if (this.showImage) {
        return;
      }

      this.controller = new AbortController();
      const { signal } = this.controller;

      try {
        const [preloadRequest, request] = await Promise.all([
          fetch(this.preloadThumbnailUrl, { signal }),
          fetch(this.thumbnailUrl, { signal }),
        ]);

        const preloadBlob = await preloadRequest.blob();
        this.preloadDataUrl = URL.createObjectURL(preloadBlob);

        const blob = await request.blob();
        this.thumbnailDataUrl = URL.createObjectURL(blob);

        this.showImage = true;
      } catch (error) {
        if (error.code !== DOMException.ABORT_ERR) {
          console.error(error);
        }
      }
    },

    async handleIntersect(entries: { isIntersecting: boolean }[]) {
      const { isIntersecting } = entries[0];

      if (!isIntersecting && this.controller) {
        this.controller.abort();
      } else if (isIntersecting) {
        await this.cacheImage();

        // local file has initial '' set
        if ((isLocalFile(this.item.location) || this.item.location === '') && !this.localThumbnailGenerated) {
          if (!this.controller || this.controller.signal.aborted) {
            this.controller = new AbortController();
          }

          const { signal } = this.controller;

          const { localFile } = await this.openLocalFile(this.item.id);
          const thumbnailUrl = await generateLocalThumbnail(localFile, this.item.id, signal);

          if (!thumbnailUrl || signal.aborted) {
            return;
          }

          this.thumbnailDataUrl = thumbnailUrl;
          this.showImage = true;
          this.localThumbnailGenerated = true;
        }
      }
    },

    openFile() {
      this.$emit('open-file', this.item);
    },

    copyShareLink() {
      const link = createShareLink(this.item, this.localeId, this.userId);
      navigator.clipboard.writeText(link);
      this.$emit('snackbar', this.$t('shareLink.txtSnackbar'));
    },

    openMail() {
      const link = createShareLink(this.item, this.localeId, this.userId);
      window.open(`mailto:?body=${link}`, '_blank');
    },

    async saveLocal() {
      try {
        await this.saveToLocal(this.item);
      } catch (err) {
        console.error(`Save local error: ${err}`);
      }
    },

    toggleFavorite() {
      if (this.favorite) {
        this.removeFavorite(this.item.id);
      } else {
        this.addFavorite(this.item);
      }
    },

    menuClicked(key: FileGridItemMenuKey) {
      switch (key) {
        case FileGridItemMenuKey.GENERATE_LINK:
          this.copyShareLink();
          break;
        case FileGridItemMenuKey.OPEN_EMAIL:
          this.openMail();
          break;
        case FileGridItemMenuKey.SAVE_LOCAL:
          this.saveLocal();
          break;
        default:
          console.error(`No menu action for key: ${key}`);
      }
    },
  },
});
