
import Vue, { PropType } from 'vue';
import * as pdfjs from 'pdfjs-dist/es5/build/pdf';
import { PDFLinkService } from 'pdfjs-dist/web/pdf_viewer';

export default Vue.extend({
  name: 'PdfDocument',

  data() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { rendered: false, renderTask: null as any };
  },

  props: {
    pdf: Object,
    pageNum: Number as PropType<number>,
    scale: {
      type: Number as PropType<number>,
      default: 1.0,
    },
  },

  watch: {
    scale: {
      handler(newValue) {
        if (newValue && this.rendered) {
          this.rendered = false;
          this.renderPage();
        } else {
          this.presizePage();
        }
      },
      immediate: true,
    },
  },

  methods: {
    async renderIntersectingPage(entries: { isIntersecting: boolean }[]) {
      if (this.rendered || !entries[0].isIntersecting) {
        return;
      }

      await this.renderPage();
    },

    pageVisible(entries: { isIntersecting: boolean }[]) {
      if (entries[0].isIntersecting) {
        this.$emit('current-page', this.pageNum);
      }
    },

    async renderPage() {
      if (this.renderTask) {
        this.renderTask.cancel();
      }

      this.rendered = true;

      const page = await this.pdf.getPage(this.pageNum);
      const viewport = page.getViewport({ scale: this.scale });

      // Prepare canvas using PDF page dimensions
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const canvasContext = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext,
        viewport,
      };
      const task = page.render(renderContext);
      // eslint-disable-next-line no-underscore-dangle
      this.renderTask = task._internalRenderTask;

      try {
        await task.promise;
      } catch (error) {
        if (error?.name !== 'RenderingCancelledException') {
          throw error;
        }
      }

      this.renderText(canvas, page, viewport);
      this.renderAnnotations(canvas, page, viewport);
    },

    async presizePage() {
      const page = await this.pdf.getPage(this.pageNum);
      const viewport = page.getViewport({ scale: this.scale });

      const canvas = this.$refs.canvas as HTMLCanvasElement;
      canvas.height = viewport.height;
      canvas.width = viewport.width;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async renderText(canvas: HTMLCanvasElement, page: any, viewport: any) {
      // eslint-disable-next-line object-curly-newline
      const { offsetLeft, offsetTop, width, height } = canvas;

      const textLayer = this.$refs.textLayer as HTMLDivElement;

      textLayer.style.left = `${offsetLeft}px`;
      textLayer.style.top = `${offsetTop}px`;
      textLayer.style.width = `${width}px`;
      textLayer.style.height = `${height}px`;

      const textContent = await page.getTextContent();

      pdfjs.renderTextLayer({
        textContent,
        container: textLayer,
        viewport,
        textDivs: [],
      });
    },

    scrollPageIntoView: (args: { pageNumber: number }) => {
      const element = document.querySelector(
        `#page-${args.pageNumber}`,
      ) as HTMLElement;

      element.scrollIntoView();
    },

    async renderAnnotations(
      canvas: HTMLCanvasElement,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      page: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      viewport: any,
    ) {
      const annotationData = await page.getAnnotations();

      if (!annotationData || annotationData.length === 0) {
        return;
      }

      // eslint-disable-next-line object-curly-newline
      const { offsetLeft, offsetTop, width, height } = canvas;

      const annotationLayer = this.$refs.annotationLayer as HTMLDivElement;

      annotationLayer.style.left = `${offsetLeft}px`;
      annotationLayer.style.top = `${offsetTop}px`;
      annotationLayer.style.width = `${width}px`;
      annotationLayer.style.height = `${height}px`;

      const linkService = new PDFLinkService();
      linkService.setDocument(this.pdf);
      linkService.setViewer({
        scrollPageIntoView: this.scrollPageIntoView,
      });

      pdfjs.AnnotationLayer.render({
        viewport: viewport.clone({ dontFlip: true }),
        div: annotationLayer,
        annotations: annotationData.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (data: any) => data.subtype === 'Link',
        ),
        page,
        linkService,
      });
    },
  },
});
