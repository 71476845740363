
import Vue from 'vue';

export default Vue.extend({
  name: 'Grid',

  data() {
    return { snackbar: false, snackbarMsg: '' };
  },

  props: {
    items: Array,
  },

  methods: {
    displaySnackbar(msg: string) {
      this.snackbarMsg = msg;
      this.snackbar = true;
    },
  },
});
