
import Vue, { PropType } from 'vue';
import { File } from '@/interfaces/file.interface';

export default Vue.extend({
  name: 'FileViewerVideo',

  data() {
    return {
      renderVideo: true,
    };
  },

  props: {
    item: Object as PropType<File>,
    visible: Boolean as PropType<boolean>,
    fileUrl: String,
  },

  watch: {
    async visible(newValue) {
      const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement;
      if (!newValue) {
        videoPlayer.pause();
        this.renderVideo = false;
      } else {
        this.renderVideo = false;
        await this.$nextTick();
        this.renderVideo = true;
      }
    },
  },
});
